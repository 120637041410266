<template>
  <div class="main-content G-flex">
    <div class="P-wrapper G-flex" :class="{ 'P-open': isOpenSettings }">
      <div class="P-left-block">
        <div class="P-left-block__search">
          <ott-input
            label="Search"
            disabled
            prependInnerIcon="mdi mdi-magnify neutral--text"
          />
        </div>
        <div class="P-left-block__icons G-flex ">
          <div class="G-flex-column G-align-center">
            <v-icon medium class="blue--text mb-1">mdi-message-text</v-icon>
            <span class="text-uppercase blue--text">chats</span>
          </div>
          <div class="G-flex-column G-align-center">
            <v-icon medium class="neutral--text mb-1">mdi-phone</v-icon>
            <span class="text-uppercase neutral--text">calls</span>
          </div>
        </div>
        <div class="P-left-block__message-types G-flex-center">
          <div v-for="(item, index) in messageTypes" :key="index" :class="{ 'mr-2': index !== 4 }">
            <div class="img-block" :class="{ active: item.type === selectedMessageType }">
              <v-tooltip top :color="item.type === selectedMessageType ? 'primary' : ''">
                <template v-slot:activator="{ on, attrs }">
                  <img v-bind="attrs" v-on="on" class="" :src="require('@/assets/images/' + item.img)" width="26" @click="selectMessageType(item, index)" />
                </template>
                <span :class="{ 'primary-tooltip-content': item.type === selectedMessageType }">{{ item.name }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
        <div>
          <ul class="G-flex P-group-list mx-auto">
            <li 
              v-for="(item, index) in messageGroups" 
              :key="index" 
              class="P-group-list__item text-uppercase"
              :class="{ active: selectedGroupType === item.id }" 
              @click="selectGroupType(item)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div v-if="isLoadingMessages" class="P-left-block__loader G-flex-center">
          <v-progress-circular indeterminate color="primary" />
        </div>
        <div v-else class="P-left-block__content">
          <div 
            v-for="(item, index) in messageList" 
            :key="index" 
            class="P-chat-block G-flex G-align-center" 
            :class="{ 'active': selectedIndex === index }"
            @click="selectMessage(item, index)"
          >
            <v-avatar 
              color="primary"
              size="55"
              class="blue--text text-uppercase"
            >
              {{ item?.client?.personalInfo?.firstname.charAt(0) }}
            </v-avatar>
            <div class="ms-4">
              <div class="G-flex G-justify-between G-align-center">
                <h3 class="secondary--text">
                  {{
item.client.status   ? `${item?.client?.personalInfo?.firstname} ${item?.client?.personalInfo?.lastname}` : `Deleted`            
                  }}
                </h3>
                <span v-if="item.unreadMessagesCount" class="P-message-count">{{ item.unreadMessagesCount }}</span>
              </div>

              <p class="neutral--text mb-0 G-flex G-align-center">
                <img src="@/assets/images/message.png" class="mr-1" width="20" style="border-radius: 50%;" />
                {{ item.lastMessage }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="P-right-block G-flex G-justify-between G-flex-column">
        <div v-if="!isLoading && separateMessages.length" class="G-flex G-align-center G-justify-between P-selected-chat">
          <h3 style="font-weight: 500;">
            <router-link :to="'search/client/' + clientId" class="secondary--text">
              {{ selectedChat?.firstname + ' ' + selectedChat?.lastname }}
            </router-link>
          </h3>
          <div class="G-flex G-align-center" style="margin-right: -14px;">
            <img v-if="$vuetify.theme.dark" src="@/assets/images/cam-recorder-white.png" width="40" class="cursor-pointer" @click="handleVideoCall" />
            <img v-else src="@/assets/images/cam-recorder-gray.png" width="40" class="cursor-pointer" @click="handleVideoCall" />
            <v-btn icon class="ml-3">
              <v-icon large class="neutral--text">mdi-phone</v-icon>
            </v-btn>
            <v-btn icon @click="openSettings">
              <v-icon large :class="{ 'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark }">mdi-dots-vertical</v-icon>
            </v-btn>
          </div>
        </div>
        <!-- <div v-if="!isLoading && separateMessages.length" class="G-flex P-inputs-block">
          <div class="P-padding-33">
            <ott-select
              v-model="selectedPhone"
              label="Phone Numbers"
              selected-text="name"
              :items="phoneList"
              :error="$v.selectedPhone.$error"
              @emitInputChange="handlePhone"
              :multiple="false"
              persistent-hint
              clear
            />
          </div>
          <div class="P-padding-33">
            <ott-select
              v-model="selectedLocation"
              label="Locations"
              selected-text="name"
              :items="allLocations"
              @emitInputChange="handleLocation"
              :disabled="!isSelectedPhone"
              :multiple="false"
              persistent-hint
              clear
            />
          </div>
          <div class="P-padding-33">
            <ott-select
              v-model="selectedAddress"
              label="Company addresses"
              selected-text="name"
              :items="allCompanyAddresses"
              @emitInputChange="handleAddress"
              :disabled="!isSelectedPhone"
              :multiple="false"
              persistent-hint
              clear
            />
          </div>
        </div> -->

        <!-- <video controls width="400">
        <source src="src" type="video/mp4">
        Your browser does not support the video tag.
        </video> -->

      
        <div class="chat-messages" :class="{ 'G-flex G-justify-center G-align-center': isLoading || isNoMessages }" ref="chatMessages" @scroll="handleScroll">
          <div v-if="!isLoading">
            <v-progress-circular v-if="isFetchingData" :width="2" :size="20" indeterminate color="primary"
            class="P-prev-data-loader"></v-progress-circular>

            <div v-for="(messages, date) in groupedMessages" :key="date">
              <div class="G-flex G-align-center">
                <hr class="P-date-line" />
                <span class="P-group-date">{{ date }}</span>
                <hr class="P-date-line" />
              </div>
              
              <div 
                v-for="(message, index) in messages" 
                :key="index"
                :class="{ 'my-message G-flex-row-reverse G-align-end': message.isMe, 'other-message G-align-end': !message.isMe, 'mb-2': !message.date }"
                class="message mb-3 "
              >
                <!-- <v-avatar
                  :color="message.isMe ? 'orange' : 'primary'"
                  size="40"
                  class="text-uppercase mr-3"
                  :class="{ 'ml-3 white--text': message.isMe, 'mr-3 blue--text': !message.isMe }"
                >
                  {{ message.isMe ? message.sender.charAt(0).toUpperCase() : selectedChat?.firstname.charAt(0) }}
                </v-avatar> -->

                <v-tooltip top :color="message.isMe ? '' : 'blue'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                      v-bind="attrs" v-on="on"
                      :color="message.isMe ? 'orange' : 'primary'"
                      size="40"
                      class="text-uppercase mr-3 cursor-pointer"
                      :class="{ 'ml-3 white--text': message.isMe, 'mr-3 blue--text': !message.isMe }"
                    >
                      {{ message.isMe ? message.sender.charAt(0).toUpperCase() : selectedChat?.firstname.charAt(0) }}
                    </v-avatar>
                  </template>
                  <span>{{ message.isMe ? message.sender : message?.messageSource }}</span>
                </v-tooltip>
                <div :class="{ 'G-flex-column G-align-end': message.isMe }">
                  <!-- <div v-if="message.isMe" class="message-sender">{{ message.sender }}</div> -->
                  <!-- <div v-if="!message.isMe" class="message-sender">{{ message?.messageSource }}</div> -->
                  <div class="message-text">
                    {{ message.text }}
                    <img
                      :src="deliverySystemImg(message)"
                      class="delivery-system"
                      width="24"
                    />
                  </div>
                  <div v-if="message.date" class="message-date G-flex mt-1 mb-1" :class="{ 'G-justify-end G-align-end mb-2': message.isMe }">
                    {{ $moment(message.date).format('hh:mm A') }}

                    <v-icon small v-if="message.isMe && message.deliveryState === 1" class="primary--text ml-1 mdi-check-all">mdi-check-all</v-icon>
                    <v-icon small v-if="message.isMe && message.deliveryState === 2" class="neutral--text ml-1">mdi-check</v-icon>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
          <p v-if="isNoMessages" class="neutral--text text-center mb-0">No messages here yet...</p>
        </div>
        <v-progress-linear v-if="isShowProgress" v-model="smsProgress" class="mb-3"></v-progress-linear>
      
        <div v-if="isAnyTyping" class="px-4 pt-4 P-typing-animation">
          {{ typingName }} is typing
        </div>
        <div class="G-flex G-align-center typing-block my-4 px-4">
          <v-icon medium @click="showIcons()" class="neutral--text mr-3">mdi-emoticon-happy-outline</v-icon>
          <ott-textarea 
            v-model="message" 
            @timeoutInput="handleTypingIndicator"
            :timeoutInput="true"
            label="Type a message..." 
            class="me-3 typing-textarea" 
            :rows="1"
            @keydown="enterMessage"
            @input="handleTyping" 
            is-auto-grow 
            ref="typingArea"
          />
          <v-btn color="blue" small fab @click="sendMessage()" class="send-btn">
            <v-icon class="white--text">mdi-send</v-icon>
          </v-btn>

          <div class="emojis-block">
            <transition name="fade">
              <Picker v-if="isShowEmojis" :data="emojiIndex" set="apple" @select="selectEmoji" />
            </transition>
          </div>
        </div>
      </div>
    </div>

    <div class="P-client-settings" :class="{ 'P-open': isOpenSettings }">
      <div class="P-client-settings__top G-flex G-align-center G-justify-between">
        <h3 class="secondary--text" style="font-weight: 500;">Client info</h3>
        <v-icon large class="neutral--text cursor-pointer" @click="openSettings">mdi-close</v-icon>
      </div>

      <div v-if="!isLoading && separateMessages.length" class="P-client-settings__info">
        <div class="P-client-settings__info-avatar G-flex G-flex-column G-align-center pb-10">
          <v-avatar 
            color="primary"
            size="125"
            class="blue--text text-uppercase"
            style="font-size: 44px;"
          >
            {{ selectedChat.firstname.charAt(0) }}
          </v-avatar>
          <h3 class="mt-3" style="font-weight: 500;">
            <router-link :to="'search/client/' + clientId" class="secondary--text">
              {{ selectedChat?.firstname + ' ' + selectedChat?.lastname }}
            </router-link>
          </h3>
          <p class="neutral--text mb-0">{{ clientData?.provider ? clientData?.provider?.name[0]?.name : '' }}</p>
          <div class="mt-1" v-html="clientStatus"></div>
        </div>

        <div v-if="!isLoading && separateMessages.length" class="P-inputs-block">
          <div class="P-padding-100">
            <ott-select
              v-model="selectedPhone"
              label="Phone Numbers"
              selected-text="name"
              :items="phoneList"
              :error="$v.selectedPhone.$error"
              @emitInputChange="handlePhone"
              :multiple="false"
              persistent-hint
              clear
            />
          </div>
          <div class="P-padding-100">
            <ott-select
              v-model="selectedLocation"
              label="Locations"
              selected-text="name"
              :items="allLocations"
              @emitInputChange="handleLocation"
              :disabled="!isSelectedPhone"
              :multiple="false"
              persistent-hint
              clear
            />
          </div>
          <div class="P-padding-100">
            <ott-select
              v-model="selectedAddress"
              label="Company addresses"
              selected-text="name"
              :items="allCompanyAddresses"
              @emitInputChange="handleAddress"
              :disabled="!isSelectedPhone"
              :multiple="false"
              persistent-hint
              clear
            />
          </div>
        </div>

        <div v-if="clientEmails.length" class="P-client-settings__info-email G-flex G-align-center">
          <div class="mr-2">
            <v-icon medium class="neutral--text">mdi-email-outline</v-icon>
          </div>
          <div>
            <p v-for="(item, index) in clientEmails" :key="index" class="mb-0">{{ item.email }}</p>
          </div>
        </div>

        <div 
          v-if="clientAddresses.length" 
          class="P-client-settings__info-address" 
          :class="{ 'G-flex G-align-start': clientAddresses.length === 1 }"
        >
          <div class="mr-2">
            <v-icon medium class="neutral--text">mdi-map-marker</v-icon>

            <span v-if="clientAddresses.length > 1" class="ml-2">Addresses</span>
          </div>

          <ul v-if="clientAddresses.length > 1" class="mt-3">
            <li v-for="(address, index) in clientAddresses" :key="index">
              <p v-html="address"></p>
            </li>
          </ul>
          
          <div v-else>
            <p v-for="(address, index) in clientAddresses" :key="index" class="mb-0" v-html="address"></p>
          </div>
        </div>

        <div v-if="clientLocations && clientLocations.length" class="P-client-settings__info-locations" >
          <span>Locations</span>

          <v-carousel hide-delimiters :show-arrows="clientLocations.length > 1 ? true : false" height="84" class="P-carousel">
            <template v-slot:prev="{ on, attrs }">
              <v-icon medium v-bind="attrs" v-on="on" class="neutral--text">mdi-chevron-left</v-icon>
            </template>
            <template v-slot:next="{ on, attrs }">
              <v-icon medium v-bind="attrs" v-on="on" class="neutral--text">mdi-chevron-right</v-icon>
            </template>
            <v-carousel-item v-for="(item, index) in clientLocations" :key="index">
              <div class="P-location text-center" :class="{ 'active': selectedClientLoc === index }" @click="selectLocation(item, index)">
                <p class="mb-0">
                  {{ item.login + ' (' + item.locationName + ')' }}
                </p>
              </div>
            </v-carousel-item>
          </v-carousel>

          <p
            class="text-center G-flex G-justify-center"
            v-html="selectedLocationStatus"
          />

          <div v-if="packagesToDisplay && packagesToDisplay.length" class="P-table">
            <div class="P-table__header">
              <ul class="G-flex">
                <li>Package name</li>
                <li>Expire date</li>
              </ul>
            </div>
            <div class="P-table__body">
              <ul v-for="(item, index) in packagesToDisplay" :key="index" class="G-flex">
                <li>{{ getPackageName(item.package.name) }}</li>
                <li>{{ item?.expireDate }}</li>
              </ul>
            </div>
          </div>
          <p v-else class="text-center">There is no packages</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OttTextarea from "@/components/vuetifyComponents/OttTextarea";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttInput from "@/components/vuetifyComponents/OttInput";

// Import data/twitter.json to reduce size, all.json contains data for
// all emoji sets.
import data from "emoji-mart-vue-fast/data/all.json";
// Import default CSS
import "emoji-mart-vue-fast/css/emoji-mart.css";

import { Picker, EmojiIndex } from 'emoji-mart-vue-fast'
let emojiIndex = new EmojiIndex(data)


import { mapState, mapMutations, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";

import ChatsMixin from "@/mixins/chats/ChatsMixin";
import HubMixin from "@/mixins/Hub.mixin.js";


export default {
  name: 'MainChat',
  components: {
    OttTextarea,
    Picker,
    OttSelect,
    OttInput
  },
  mixins: [ChatsMixin, HubMixin],
  props: {
    // clientId: {
    //   type: String,
    //   default: null,
    //   required: false
    // },
  },

  data() {
    return {
      messageGroups: [
        {
          id: 1,
          name: 'Clients'
        },
        // {
        //   id: 2,
        //   name: 'Team'
        // }
      ],
      messageTypes: [
        {
          name: "Message",
          type: "sms",
          img: "message.png"
        },
        {
          name: "Viber",
          type: "viber",
          img: "viber.png"
        },
        {
          name: "Whatsapp",
          type: "whatsapp",
          img: "whatsapp.png"
        },
        {
          name: "Instagram",
          type: "instagram",
          img: "instagram.png"
        },
        {
          name: "Messenger",
          type: "messenger",
          img: "messenger.png"
        }
      ],
      selectedGroupType: 1,
      message: '',
      isShowEmojis: false,
      separateMessages: [],
      selectedIndex: 0,
      allMessages: [],
      isNoMessages: false,
      isFetchingData: false,
      isLoading: true,
      allDataLoaded: false,
      phoneList: [],
      selectedPhone: '',
      isAnyTyping: false,
      typingName: '',
      typingTimeout: null,
      isSelectedPhone: false,
      selectedLocation: '',
      selectedAddress: '',
      selectedMessageType: 'sms',
      allCompanyAddresses: [],
      allLocations: [],
      emojiIndex: emojiIndex,
      isOpenSettings: true,
      clientEmails: [],
      clientAddresses: [],
      selectedClientLoc: 0,
      clientLocations: [],
      selectedLocationData: ''
    }
  },
  validations: {
    selectedPhone: { required },
  },
  async mounted() {
    this.$hubEventBus.$on(`provider-chat`, this.handleProviderChatSocket); // if loggedIn get permissions with WS
    this.setData({ isFirstMessage: true, isSendMessage: false })
    await this.fetchMessages({ setClient: true})
    if (this.clientId !== undefined) {
      this.subscribeHubGroup(null, `chat-client-${this.clientId}`, this.handleClientChatSocket);
      await this.getContact({ clientId: this.clientId })
      this.setDataSeparateChat({ currentPage: 1 })
      await this.handleChat()
      this.scrollToBottom();

      if (this.clientLocations.length) {
        let locId = this.clientLocations[0].id
        this.selectedLocationData = this.clientLocations[0]

        await this.getSubscriptions({ location: locId }).then(data => {
          this.setData({ packagesData: data })
        })
      }
    } else {
      this.isLoading = false
    }

    if (this.companyAddressList.length) {
      this.companyAddressList.map(item => {
        this.allCompanyAddresses.push({
          name: item.name,
          value: item.name,
        })
      })
    }
  },
  beforeDestroy() {
    this.$hubEventBus.$off(`provider-chat`, this.handleProviderChatSocket); // if loggedIn get permissions with WS

    if (this.clientId) {
      this.subscribeHubGroup(`chat-client-${this.clientId}`, null, this.handleClientChatSocket);
    }
  },
  computed: {
    groupedMessages() {
      const groupedMessages = this.separateMessages.reduce((grouped, message) => {
        const messageDate = this.$moment(message.date)
        const dateKey = messageDate.format('dddd DD MMM') // Formatting date as 'dddd DD MMM'

        if (!grouped[dateKey]) {
          grouped[dateKey] = []
        }
        grouped[dateKey].push(message)

        return grouped;
      }, {})

      return groupedMessages
    },
    ...mapState({
      hubState: (state) => state.appModule.hubState,
      messageList: state => state.mainChatModule.messageList,
      isLoadingMessages: state => state.mainChatModule.isLoadingMessages,
      clientId: state => state.mainChatModule.clientId,
      authProviderId: (state) => state.auth.user.provider.id,
      authUserId: (state) => state.auth.user.id,
      accessToken: (state) => state.auth.tokens.access.token,
      currentPage: state => state.chatModule.currentPage,
      totalPages: state => state.mainChatModule.totalPages,
      allPhones: state => state.contactClient.allPhones,
      isShowProgress: state => state.chatModule.isShowProgress,
      companyAddressList: state => state.companyAddressesAdmin.fullAddressList,
      
      clientData: state => state.contactClient.clientData,
      smsProgress: state => state.chatModule.smsProgress,
      selectedChat: state => state.mainChatModule.selectedChat, 
      isFirstMessage: state => state.mainChatModule.isFirstMessage,
      packagesData: state => state.mainChatModule.packagesData,
      isSendMessage: state => state.mainChatModule.isSendMessage
    }),

    clientStatus() {
      if (this.clientData) {
        if (this.clientData.subscriptionState === 0) {
          return `<span class="G-badge G-advanced-badge">Inactive</span>`
        }

        if (this.clientData.subscriptionState === 1) {
          return `<span class="G-badge G-pending-badge">Pending</span>`
        }

        if (this.clientData.subscriptionState === 2) {
          return `<span class="G-badge G-disabled-badge">Canceled</span>`
        }

        if (this.clientData.subscriptionState === 3) {
          if (this.clientData.inBlock === 0 && this.clientData.inPause === 0) {
            return `<span class="G-badge G-approve-badge">Active</span>`
          }

          if (this.clientData.inBlock === 0 && this.clientData.inPause === 1) {
            return `<span class="G-badge G-paused-badge">Paused</span>`
          }

          if (this.clientData.inBlock === 0 && this.clientData.inPause === 2) {
            return `<span class="G-badge G-paused-badge">Semipaused</span>`
          }

          if (this.clientData.inBlock === 1 && this.clientData.inPause === 0) {
            return `<span class="G-badge G-reject-badge">Blocked</span>`
          }

          if (this.clientData.inBlock === 2 && this.clientData.inPause === 0) {
            return `<span class="G-badge G-reject-badge">Semiblocked</span>`
          }

          if (this.clientData.inBlock === 1 && this.clientData.inPause === 1) {
            return `<span class="G-badge G-reject-badge">Blocked</span> <br>
											<span class="G-badge G-paused-badge">Paused</span>`
          }

          if (this.clientData.inBlock === 1 && this.clientData.inPause === 2) {
            return `<span class="G-badge G-reject-badge">Blocked</span> <br>
												<span class="G-badge G-paused-badge">Semipaused</span>`
          }

          if (this.clientData.inBlock === 2 && this.clientData.inPause === 1) {
            return `<span class="G-badge G-reject-badge">Semiblocked</span> <br>
											<span class="G-badge G-paused-badge">Paused</span>`
          }

          if (this.clientData.inBlock === 2 && this.clientData.inPause === 2) {
            return `<span class="G-badge G-reject-badge">Semiblocked</span> <br>
											<span class="G-badge G-paused-badge">Semipaused</span>`
          }
        }

        if (
          this.clientData.inBlock === undefined || this.clientData.inPause === undefined
          || (this.clientData.inBlock === 0 && this.clientData.inPause === 0)
        ) {
          return ''
        }
      }
    },

    packagesToDisplay() {
      if (this.packagesData) {
        return this.packagesData?.active.length > 0
          ? this.packagesData.active
          : this.packagesData.inactive; 
      }
    },
    selectedLocationStatus() {
      /*
        location status by subscriptionState value
        0 - Inactive
        1 - Pending
        2 - Canceled
        3 - Active
      */

      let status = "";
      let pauseDuration = null;
      let expireDate = null;
      let pauseStartDate = null;

      if (this.selectedLocationData) {
        const location = this.selectedLocationData;

        //get pause duration
        if (location?.subscriptionExpireDate) {
          expireDate = this.$moment(location.subscriptionExpireDate);
          pauseStartDate = this.$moment(location.pauseStartDate);
          pauseDuration = this.convertMsToDays(expireDate.diff(pauseStartDate));
        }

        if (
          location.isBlockLocation === undefined ||
          location.isPauseSubscriptions === undefined ||
          (!location.isBlockLocation && !location.isPauseSubscriptions)
        ) {
          status = "";
        }

        if (location.subscriptionState === 0) {
          status = `<span class="G-badge G-advanced-badge">Inactive</span>`;
        }

        if (location.subscriptionState === 1) {
          status = `<span class="G-badge G-pending-badge">Pending ${location?.subscriptionPendingDate
            ? location.subscriptionPendingDate
            : ""
            }</span>`;
        }

        if (location.subscriptionState === 2) {
          status = `<span class="G-badge G-disabled-badge">Canceled ${location?.subscriptionCancelDate
            ? location.subscriptionCancelDate
            : ""
            }</span>`;
        }

        if (location.subscriptionState === 3) {
          if (location.isBlockLocation && location.isPauseSubscriptions) {
            status = `<span class="G-badge G-reject-badge mb-2">Blocked</span>
                      <span class="G-badge G-paused-badge">Paused ${pauseDuration}</span>`;
          }

          if (location.isBlockLocation && !location.isPauseSubscriptions) {
            status = `<span class="G-badge G-reject-badge">Blocked</span>`;
          }

          if (!location.isBlockLocation && location.isPauseSubscriptions) {
            status = `<span class="G-badge G-paused-badge">Paused ${pauseDuration}</span>`;
          }

          if (!location.isBlockLocation && !location.isPauseSubscriptions) {
            status = `<span class="G-badge G-approve-badge">Active ${location?.subscriptionActivationDate
              ? location.subscriptionActivationDate
              : ""
              }</span>`;
          }
        }
      }

      return status;
    },
  },
  watch: {
    'allPhones'(allPhones) {
      if (allPhones.length) {
        this.phoneList = []
        allPhones.filter(r => r.forMessages).map(item => {
          this.phoneList.push({
            name: item.phone,
            value: item.phone
          })
        })

        if (this.phoneList.length === 1) {
          this.selectedPhone = this.phoneList[0].value
        }
      }
    },
    
    'clientData'(clientData) {
      this.clientEmails = []
      this.clientAddresses = []
      if (clientData.locations.length) {
        this.allLocations = []
        this.clientLocations = []
        clientData.locations.map(item => {
          this.allLocations.push({
            name: item.login,
            value: item.login,
            login: item.login,
            password: item.password,
            locationName: item.locationName,
            id: item.id
          })
        })
        this.clientLocations = clientData.locations
      }

      if (clientData.emails.length) {
        this.clientEmails = clientData.emails
      }

      if (clientData.addresses.length) {
        clientData.addresses.map(item => {
          let fullAddress = ''

          if (item.address) {
            fullAddress = fullAddress + item.address
          }
          if (item.suite) {
            fullAddress = fullAddress + ' ' + item.suite
          }
          if (item.city) {
            fullAddress = fullAddress + '<br />' + item.city
          }
          if (item.province) {
            fullAddress = fullAddress + ' ' + item.province
          }
          if (item.zip) {
            fullAddress = fullAddress + ' ' + item.zip
          }
          if (item.country && item.country !== 'US') {
            fullAddress = fullAddress + ' ' + item.country
          }
          this.clientAddresses.push(fullAddress)
        })
      }
    },
    selectedPhone(newVal) {
      this.isSelectedPhone = newVal !== null
    },
    clientId(_new, old) {
      this.subscribeHubGroup(old ? `chat-client-${old}` : null, _new ? `chat-client-${_new}` : null, this.handleClientChatSocket);
    },
    hubState() {
      if (this.clientId && this.hubState === 5) {
        this.subscribeHubGroup(null, `chat-client-${this.clientId}`, this.handleClientChatSocket);
      }
    },
  },
  methods: {
    ...mapActions({
      fetchMessages: 'mainChatModule/fetchMessages',
      getMessagesById: 'chatModule/getMessages',
      getContact: 'contactClient/getContact',
      fetchUnreadMessages: 'mainChatModule/fetchUnreadMessages',
      sentClientMessage: 'chatModule/sendMessage',
      getSubscriptions: 'packagesModule/getSubscriptions',
    }),
    ...mapMutations({
      setData: 'mainChatModule/setData',
      setDataSeparateChat: 'chatModule/setData'
    }),
    async handleTypingIndicator() {
      this.$sendData(JSON.stringify({ action: 'chatUserTyping', clientId: this.clientId, userId: this.authUserId }));
      console.log(`typing`);
    },
    async handleProviderChatSocket(data) {
      debugger
      await this.fetchMessages({ setClient: false})
      await this.fetchUnreadMessages({ provider: this.authProviderId});
        // this.setDataSeparateChat({ currentPage: 1 })
        // this.handleChat()
    },
    async handleClientChatSocket(data) {
      const self = this;
      if (this.clientId) {
        if (data.action === 'newChat') {
          this.setDataSeparateChat({ currentPage: 1 })
          await this.handleChat()
        } else if (data.action === 'typing') {
          if (data.token !== self.accessToken)  {
            this.isAnyTyping = true;
            this.typingName = data.name;
          if (self.typingTimeout) {
            clearTimeout(self.typingTimeout);
          }
          self.typingTimeout = setTimeout(() => {
            self.isAnyTyping = false;
            self.typingTimeout = null;
          }, 2000)
          }
        }
      }
    },
    async selectGroupType(item) {
      this.selectedGroupType = item.id
      this.separateMessages = []
      this.allMessages = []
      this.isLoading = true
      this.allDataLoaded = false
      if (this.selectedGroupType === 1) {
        this.setData({ chatType: 'client', isLoadingMessages: true })
        await this.fetchMessages({ setClient: true })
        if (this.clientId !== undefined) { 
          await this.handleChat();
          this.subscribeHubGroup(old ? `chat-client-${old}` : null, _new ? `chat-client-${_new}` : null, this.handleClientChatSocket);
        } else {
          this.isLoading = false
        }
      } else {
        this.setData({ chatType: 'provider', isLoadingMessages: true })
        await this.fetchMessages({setClient: true})
        this.subscribeHubGroup(old ? `chat-user-${old}` : null, _new ? `chat-user-${_new}` : null, this.handleClientChatSocket);
        if (this.clientId !== undefined) {
          await this.handleChat()
        } else {
          this.isLoading = false
        }
      }
    },
    selectEmoji(emoji) {
      this.message += emoji.native
      this.$nextTick(() => {
        this.$refs.typingArea.focusTextarea()
      })
    },
    handleTyping() { },
    enterMessage(event) {
      event.preventDefault()
      this.sendMessage()
    },

    async selectMessage(item, index) {
      if (index !== this.selectedIndex) {
        this.setDataSeparateChat({ currentPage: 1 })
        this.setData({ totalPages: 1 })
        this.isFetchingData = true
        this.separateMessages = []
        this.allMessages = []
        this.isLoading = true
        this.allDataLoaded = false
        this.allLocations = []
        this.clientLocations = []
        this.selectedPhone = ''
        this.isSelectedPhone = false
        this.selectedIndex = index
        this.selectedClientLoc = 0
        this.setData({ clientId: item.client?.id, isSendMessage: false })
        await this.fetchMessages({ setClient: false })
        await this.fetchUnreadMessages({ provider: this.authProviderId });
        this.setData({ selectedChat: item.client.personalInfo, isFirstMessage: false })
        // this.setDataSeparateChat({ currentPage: 1 })
        await this.getContact({ clientId: this.clientId })
        this.$v.$reset()

        if (this.clientLocations.length) {
          let locId = this.clientLocations[0].id
          this.selectedLocationData = this.clientLocations[0]

          await this.getSubscriptions({ location: locId }).then(data => {
            this.setData({ packagesData: data })
          })
        }

        await this.handleChat()
        this.scrollToBottom();
      }
    },
    fetchList(results) {
      const incoming = [];
      results.forEach((item) => {
        const isMe = item.from_client !== this.clientId;
        incoming.push({
          isMe,
          date: new Date(item.createdAt),
          text: item.message,
          sender: isMe && item.user?.id
            ? `${item.user.firstname} ${item.user.lastname}`
            : (item.byUser
              ? `${item.byUser.firstname} ${item.byUser.lastname}`
              : ''),
          deliveryState: item.deliveryState,
          messageSource: item?.messageSource,
          deliverySystem: item.deliverySystem
        })
      })
      const newList = incoming.reverse();
      // TO DO - concat new loaded messages, but when send new message does not get again
      // this.separateMessages = newList.concat(this.separateMessages)
      if (this.isSendMessage) {
        this.separateMessages = newList
      } else {
        this.separateMessages = newList.concat(this.separateMessages)
      }
      
      this.$forceUpdate()
      return this.separateMessages
    },

    async handleChat() {
      const data = await this.getMessagesById({ filter: {}, clientId: this.clientId })
      this.setData({ totalPages: data.data.totalPages })
        // this.setDataSeparateChat({ currentPage: 1 })
        this.fetchList(data.data.results)
        this.setDataSeparateChat({ isShowProgress: false, smsProgress: 0 })
        this.isLoading = false
        this.isFetchingData = false
        this.selectedLocation = ''
        this.selectedAddress = ''
    },

    async handleScroll() {
      const scrollContainer = this.$refs.chatMessages

      if (scrollContainer.scrollTop === 0 && !this.allDataLoaded) {
        if (this.totalPages > 1 && this.currentPage < this.totalPages) {
          console.log('this.totalPages > 1 && this.currentPage < this.totalPages')
          this.isFetchingData = true
          this.setDataSeparateChat({ currentPage: this.currentPage + 1 })
          this.setData({ isSendMessage: false })
          await this.handleChat() 
        }
      }
    },

    handlePhone(value) {
      this.isSelectedPhone = true
    },
    showIcons() {
      this.isShowEmojis = !this.isShowEmojis
    },
    async sendMessage() {
      this.$v.selectedPhone.$touch()
      if (!this.$v.selectedPhone.$error && this.message.trim() !== '') {
        this.isShowEmojis = false
        this.setDataSeparateChat({ isShowProgress: true })
        try {
          await this.sentClientMessage({ clientId: this.clientId, data: { message: this.message, phone: this.selectedPhone, type: this.selectedMessageType } })
          this.message = ''
          this.setDataSeparateChat({ currentPage: 1 })
          this.setData({ isSendMessage: true })
          await this.handleChat()
          this.scrollToBottom()
        } catch (errr) {
          this.toast({ success: false, message: errr })
        }
      }
    },

    handleTyping(val) {
      this.isShowEmojis = false

      if (!(this.message.includes(this.selectedLocation) && this.message.includes(this.selectedPass))) {
        this.selectedLocation = ''
      }

      if (!this.message.includes(this.selectedAddress)) {
        this.selectedAddress = ''
      }
    },

    handleAddress(value) {
      if (value) {
        this.message += value + '\n'
      }
      const length = this.message.length
      this.$nextTick(() => {
        this.$refs.typingArea.focusTextareaWithLength(length)
      })
    },

    handleLocation(value) {
      if (value) {
        const selectedLoc = this.allLocations.filter(item => item.login === value)
        this.selectedPass = selectedLoc[0].password
        this.message += 'login: ' + selectedLoc[0].login + ',' + '\n' + 'password: ' + selectedLoc[0].password + '\n'
      }
      const length = this.message.length
      this.$nextTick(() => {
        this.$refs.typingArea.focusTextareaWithLength(length)
      })
    },

    selectMessageType(val) {
      this.selectedMessageType = val.type
    },

    handleVideoCall() { },

    deliverySystemImg(message) {
      let deliverySystem = message.deliverySystem

      switch (deliverySystem) {
        case 'twilio':
          console.log(deliverySystem);
          return require('@/assets/images/message.png')
          break
        case 'viber':
          return require('@/assets/images/viber.png')
          break
        case 'instagram':
          return require('@/assets/images/instagram.png')
          break
        case 'whatsapp':
          return require('@/assets/images/whatsapp.png')
          break
        case 'messenger':
          return require('@/assets/images/messenger.png')
          break
      }
    },

    openSettings() {
      this.isOpenSettings = !this.isOpenSettings
    },

    async selectLocation(item, index) {
      this.selectedLocationData = item
      this.selectedClientLoc = index

      await this.getSubscriptions({ location: item.id }).then(data => {
        this.setData({ packagesData: data })
      })
    },

    getPackageName(arr) {
      let eng = arr.filter(name => name.lang === 'en' || name.lang === 'EN')
      let ru = arr.filter(name => name.lang === 'ru' || name.lang === 'RU')
      if (eng.length) {
        return eng[0].name
      }
      if (ru.length) {
        return ru[0].name
      }
    },

    convertMsToDays(t) {
      let cd = 24 * 60 * 60 * 1000,
        ch = 60 * 60 * 1000,
        d = Math.floor(t / cd),
        h = Math.floor((t - d * cd) / ch),
        m = Math.round((t - d * cd - h * ch) / 60000);

      return `${d}d ${h}h ${m}m`;
    },
  },
};
</script>

<style scoped lang="scss">
@import "./src/assets/scss/variables";
.v-application ol, .v-application ul {
  padding-left: 0;
}
// .v-application--is-ltr .ms-2 {
//   margin-left: 0 !important;
// }
.P-group-list {
  background-color: #eaedf2;
  border-radius: 4px;
  width: fit-content;
  margin: 14px;
  padding: 5px !important;
  &__item {
    border-radius: 4px;
    padding: 6px 16px;
    cursor: pointer;
    transition: all ease 0.3s;
    font-weight: 500;
    &.active {
      background-color: $blue;
      color: #fff;

      // &:hover {
      //   background-color: #01B2B8;
      //   color: #fff;
      // }
    }

    // &:first-child {
    //   margin-right: 10px;
    // }

    // &:hover {
    //   background-color: $neutral-color25;
    // }
  }
}

.P-left-block {
  max-width: 385px;
  width: 100%;
  border-right: 1px solid $neutral-color75;
  padding-bottom: 20px;

  &__loader {
    height: calc(100% - 45px);
  }

  &__content {
    overflow-y: auto;
    height: calc(100% - 284px);
  }

  &__message-types {
    height: 62px;
    border-bottom: 1px solid #eaeaea;

    > div {
      width: 26px;
      height: 26px;
    }
    .img-block {
      height: 100%;
      position: relative;

      img {
        height: 26px;
        width: 26px;
        filter: grayscale(1);
        cursor: pointer;
        border-radius: 4px;
      }

      &.active {
        img {
          filter: none;
        }

        .status {
          width: 12px;
          height: 12px;
          display: block;
          background-color: green;
          position: absolute;
          right: -5px;
          bottom: 3px;
          border-radius: 50%;
        }
      }
    }
  }

  &__search {
    padding: 14px;
    border-bottom: 1px solid #eaeaea;
  }

  &__icons {
    padding: 14px;
    border-bottom: 1px solid #eaeaea;
    justify-content: space-evenly;
    span {
      font-weight: 500;
    }
  }
}

.P-wrapper {
  width: 100%;
  transition : $transition;

  &.P-open {
    width: calc(100% - 385px);
  }
}

.P-inputs-block {
  border-bottom: 1px solid #eaeaea;
  padding: 11px 4px;
}

.P-right-block {
  width: 100%;
  height: 100%;

  .P-selected-chat {
    padding: 13px 14px;
    border-bottom: 1px solid #eaeaea;
  }
}

.P-chat-block {
  padding: 10px 14px;
  transition: all ease 0.2s;
  border-bottom: 1px solid #eaeaea;
  border-left: 2px solid transparent;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: $neutral-color15;
    cursor: pointer;
  }

  &.active {
    background-color: $neutral-color25;
    border-left: 2px solid $blue;
    // &::before {
    //   content: '';
    //   border-left: 1px solid $blue;
    // }
  }

  .v-avatar {
    font-size: $txt20;
  }

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 261px;
  }

  .P-message-count {
    width: 20px;
    height: 20px;
    background-color: $green;
    display: block;
    border-radius: 50%;
    text-align: center;
    color: white;
    font-size: 14px;
  }
}

.v-avatar.primary {
  background-color: #c7e0fa !important;
}
.main-content {
  height: calc(100vh - 275px);
  width: 100%;
  position: relative;

  textarea {
    line-height: 1.5;
    font-size: 14px;
  }

  .message-types {
    .img-block {
      position: relative;

      img {
        height: 26px;
        width: 26px;
        filter: grayscale(1);
        cursor: pointer;
        border-radius: 4px;
      }

      &.active {
        img {
          filter: none;
        }

        .status {
          width: 12px;
          height: 12px;
          display: block;
          background-color: green;
          position: absolute;
          right: -5px;
          bottom: 3px;
          border-radius: 50%;
        }
      }
    }
  }

  .P-inputs {
    margin: 0 -10px;
  }
}

.chat-messages {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  // max-height: 250px;
  // height: 250px;
  // height: calc(100vh - 130px);
  padding: 10px;
  background-color: $neutral-color25;
  // background-image: url("../assets/images/chat-bg.png");
  background-size: cover;
  background-position: center;

  .P-prev-data-loader {
    margin: 0 47%;
  }

  .message {

    width: 50%;
    white-space: pre-line;

    &.my-message {
      margin-left: auto;

      .message-text {
        background-color: $blue;
        color: #fff;
        border-bottom-left-radius: 15px;

        .delivery-system {
          left: -28px;
        }
      }

      .v-avatar.orange {
        background-color: #f3a2008c !important;
      }

      .message-date {
        text-align: end;
      }
    }

    &.other-message {
      .message-text {
        background-color: #fff;
        border-bottom-right-radius: 15px;

        .delivery-system {
          right: -28px;
        }
      }

      .v-avatar.indigo {
        background-color: #3f51b596 !important;
      }
    }

    .message-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;
    }

    .message-sender {
      font-weight: 500;
    }

    .message-text {
      max-width: 100%;
      width: fit-content;
      word-wrap: break-word;
      // margin: 8px;
      padding: 10px;
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
      position: relative;

      .delivery-system {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }
    }

    .message-sender,
    .message-date {
      font-size: 12px;
      color: #777;
    }
  }
}

.typing-block {
  position: relative;

  .emojis-block {
    position: absolute;
    bottom: 50px;
    // right: 0;
  }
}

::v-deep .typing-block .v-input__slot {
  border-radius: 18px !important;
}

.theme--dark {
  .P-sms-cont {
    background: $neutral-color75;
  }

  .chat-messages {
    background-color: #36393C;

    .message.other-message .message-text {
      color: $secondary-color-dark
    }
  }
  .P-client-settings {
    background: $secondary-color-dark;
  }
  .P-table {
    ul {
      li {
        color: #fff;
      }
    }
  }
}

.send-btn.theme--dark {
  background-color: $primary-color !important;
}

.mdi-check-all.theme--dark {
  color: $primary-color !important;
}

.v-application.theme--dark .primary-tooltip-content {
  color: $dark_light;
}

::v-deep .typing-textarea .v-input__slot {
  margin-bottom: 0 !important;
}

::v-deep {
  .send-btn.v-btn {
    height: 38px;
    box-shadow: none;
  }

  .typing-block textarea {
    max-height: 144px !important;
    overflow-y: auto;
  }
}

::v-deep .typing-block .v-input__append-inner {
  align-self: flex-end !important;
  margin-bottom: 6px !important;
}
.theme--dark {
  .v-avatar {
    background-color: #c7e0fa !important;
  }

  .P-chat-block {
    &.active,
    &:hover {
      background-color : transparent !important;
    }
  }
} 
.P-typing-animation:after {
  content: ' .';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
  0%, 20% {
    color: $primary-color;
    text-shadow: .25em 0 0 $primary-color, .5em 0 0 $primary-color;
  }
  40% {
    color: $primary-color;
    text-shadow: .25em 0 0 $primary-color, .5em 0 0 $primary-color;
  }
  60% {
    text-shadow: .25em 0 0 blue, .5em 0 0 rgba(0,0,0,0);
  }
  80%, 100% { 
    text-shadow: .25em 0 0 green, .5em 0 0 green;
  }
}

::v-deep .v-input__slot {
  margin: 0 !important;
}


.P-client-settings {
  max-width: 385px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-left: 1px solid $neutral-color75;
  position: absolute;
  right: -414px;
  transition: $transition;
  top: 0;

  &.P-open {
    right: 0;
  }

  &__top {
    padding: 15px 14px;
    border-bottom: 1px solid #eaeaea;
  }

  &__info {
    height: calc(100% - 68px);
    overflow-y: auto;
    padding: 21px 0;

    &-email,
    &-address {
      border-bottom: 1px solid #eaeaea;
      padding: 14px;
    }

    &-avatar {
      border-bottom: 1px solid #eaeaea;
    }

    &-address {
      ul {
        padding-left: 25px;
        li {
          list-style-type: circle;
        }
      } 
    }

    &-locations {
      padding: 14px 14px 0;
      .P-location {
        padding: 8px 10px;
        margin: 20px auto;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.0784313725);
        border-radius: 18px;
        transition: 0.3s;
        min-width: 150px;
        width: fit-content;
        cursor: pointer;

        &.active {
          background-color: $primary-color;
          color: #fff
        }
      }
    }
  }
}

::v-deep .P-carousel {
  .v-window__next,
  .v-window__prev {
    background-color: transparent;
    top: calc(50% - 16px);
    margin: 0 5px;
  }
}
.P-group-date {
  width: 100%;
  max-width: fit-content;
  padding: 0 14px;
  color: #777;
}

.P-date-line {
  width: 100%;
}
.P-table {
  padding: 20px 20px 0;
  ul {
    padding: 0;
    li {
      width: 50%;
      border: 1px solid $neutral-color45;
      border-right: none;
      padding: 8px;
      font-size: $txt14;
      color: $secondary-color;
      &:last-child {
        border-right: 1px solid $neutral-color45;
      }
    }
  }
  &__header {
    ul {
      li {
        font-weight: $semiBold;
        border-bottom: none;
      }
    }
  }
  &__body {
    ul {
      li {
        border-bottom: none;
      }
      &:last-child {
        li {
          border-bottom: 1px solid $neutral-color45;;
        }
      }
    }
  }
}



@media (max-width: 1550px) {
  .P-left-block {
    max-width: 344px;
  }

  .P-client-settings {
    max-width: 320px;
  }

  .P-chat-block {
    p {
       width: 214px;
    }
  }

  .P-wrapper {
    &.P-open {
      width: calc(100% - 320px);
    }
  }
}
</style>
