import { mapState } from "vuex"

export default {

    computed: {
        ...mapState({
        })
    },

    methods: {
        subscribeHubGroup(old, _new, func) {
            if (old) {
                const groupName = old;
                this.$sendData(JSON.stringify({ action: 'removeGroup', group: groupName}));
                console.log(`unsubscribed to ${groupName}`);
                this.$hubEventBus.$off(groupName, func); // if loggedIn get permissions with WS
            }
            if (_new) {
                const groupName = _new;
                this.$sendData(JSON.stringify({ action: 'addGroup', group: groupName}));
                console.log(`subscribed to ${groupName}`);
                this.$hubEventBus.$off(groupName, func); // if loggedIn get permissions with WS
                this.$hubEventBus.$on(groupName, func); // if loggedIn get permissions with WS
            }
        },
    }
}