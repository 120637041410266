export default {
  methods: {
    formatMessageDate(date) {
      const currentDate = new Date()
      const messageDate = new Date(date)

      // check if it is today
      if (
        messageDate.getDate() === currentDate.getDate() &&
        messageDate.getMonth() === currentDate.getMonth() &&
        messageDate.getFullYear() === currentDate.getFullYear()
      ) {
        const time = messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        return `Today, ${messageDate.getDate()} ${this.getMonthName(messageDate.getMonth())} at ${time}`
      }

      // check if it is yesterday
      const yesterday = new Date(currentDate)
      yesterday.setDate(currentDate.getDate() - 1)
      if (
        messageDate.getDate() === yesterday.getDate() &&
        messageDate.getMonth() === yesterday.getMonth() &&
        messageDate.getFullYear() === yesterday.getFullYear()
      ) {
        const time = messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        return `Yesterday, ${messageDate.getDate()} ${this.getMonthName(messageDate.getMonth())} at ${time}`
      }

      // else
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
      const dayAbbreviation = days[messageDate.getDay()]
      const time = messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      return `${dayAbbreviation}, ${messageDate.getDate()} ${this.getMonthName(messageDate.getMonth())} at ${time}`
    },

    getMonthName(monthIndex) {
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ]
      return months[monthIndex]
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.chatMessages;
        container.scrollTop = container.scrollHeight;
      });
    },
  }
}