<template>
  <div class="P-users-list">
    <div class="G-page-component">
      <div class="G-page-title-block">
        <div class="G-component-title d-flex">
          <h3 class="secondary--text">Chats</h3>
        </div>
      </div>
      <div class="G-component-body page-background pa-0">
        <MainChat />
      </div>
    </div>
  </div>
</template>

<script>
import MainChat from '@/components/MainChat.vue'


export default {
  components: {
    MainChat
  },
  data() {
    return {
      
    }
  },
  mounted() {
    
  },
  updated() {
    
  },
  methods: {
    
  },
}
</script>
<style scoped lang="scss">
@import "./src/assets/scss/variables";

</style>